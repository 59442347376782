import { DataGrid } from "@mui/x-data-grid";
import NoDataImg from "../Common/NoDataImage";
import { TablePagination } from "@mui/material";

export const DataListingComponent = (props) => {
  const {
    data,
    loading,
    PAGE_SIZE_OPTIONS,
    columns,
    totalData,
    page,
    rowsPerPage,
    handlePageChange,
    handleRowsPerPageChange,
    hidefooter,
  } = props;

  return (
    <>
      {
        <>
          <DataGrid
            rows={data ?? []}
            pagination
            loading={loading}
            hideFooter
            disableColumnMenu
            disableAutosize
            slots={{ noRowsOverlay: NoDataImg }}
            disableColumnFilter
            columns={columns}
            hideFooterSelectedRowCount
            getRowId={(e) => e?.id}
            slotProps={{
              loadingOverlay: {
                variant: "skeleton",
                noRowsVariant: "skeleton",
              },
            }}
            paginationMode="server"
            sx={{
              cursor: "pointer",
              "& .MuiDataGrid-root": {
                rowBorderColor: "black",
              },
              "& .MuiDataGrid-cell": {
                textAlign: "center",
                color: "#fff",
                borderWidth: "0px 1px 1px 1px",
                borderStyle: "solid",
                borderColor: "#D8D4FF52",
                background: "#BBB1FF2E",
                "&:focus": { outline: "none" },
              },
              ".MuiDataGrid-cell:focus": { outline: "none" },
              ".MuiDataGrid-columnHeaderTitle": {
                fontWeight: "500",
                color: "#fff",
              },
              "& .super-app-theme--header": {
                background: "#3F179B",
                borderWidth: "0px 1px 1px 1px",
                borderStyle: "solid",
                borderColor: "#D8D4FF52",
              },
            }}
            pageSize={rowsPerPage}
          />
          {!hidefooter && (
            <TablePagination
              component="div"
              sx={{
                color: "#D8D4FF",
                ".MuiSvgIcon-root": {
                  color: "#D8D4FF",
                },
              }}
              count={totalData}
              page={page}
              onPageChange={handlePageChange}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleRowsPerPageChange}
              rowsPerPageOptions={PAGE_SIZE_OPTIONS}
            />
          )}
        </>
      }
    </>
  );
};
