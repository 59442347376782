import { Box, Button, TextField, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import ReactQuill from "react-quill";
import { useFormik } from "formik";
import * as Yup from "yup";
import { GET_API, POST_API } from "../../Common/Api/Function";
import apiEndpoints from "../../Common/Api/apiEndpoints";
import SingleImage from "../../Component/SingleImage";
import { BaseUrl } from "../../Common/Constant";
import { toast } from "react-toastify";
import Loader from "../../Common/Loader";
import { useLocation, useNavigate } from "react-router-dom";
import PathList from "../../Common/PathList";
import logo from "../../Assets/image/logo.svg";
import backIcon from "../../Assets/image/back.png";

const AddNewsScreen = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const isEditMode = location.search === "?edit" ?? false;

  const validationSchema = Yup.object().shape({
    content: Yup.string().required("Content is required"),
    heading: Yup.string().required("Heading is required."),
    subtitle: Yup.string().required(
      isEditMode ? null : "Sub-title is required."
    ),
    image: Yup.mixed().required(isEditMode ? null : "Image is required."),
  });

  const initialValues = {
    content: isEditMode ? location.state.data.description : "",
    image: isEditMode ? location.state.data.image : null,
    heading: isEditMode ? location.state.data.heading : "",
    subtitle: isEditMode ? location.state.data.subtitle : "",
  };

  
  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
          setLoading(true);
          const formData = new FormData();
          formData.append("image", values.image);
          formData.append("heading", values.heading);
          formData.append("subtitle", values.subtitle);
          formData.append("description", values.content);

          const res = await POST_API(
            isEditMode
              ? apiEndpoints.updateNews(location.state.data.id)
              : apiEndpoints.addnews,
            formData
          );
          if (res.s) {
            isEditMode
              ? toast.success("News Updated Successfully")
              : toast.success("News Added Successfully");
            navigate(PathList._News);
          }
          setLoading(false);
      } catch (error) {
        console.log(error);
        toast.error("Failed to update news.");
      } finally {
        setLoading(false);
        setSubmitting(false);
      }
    },
  });

  return (
    <Box sx={{ background: "#05001B", height: "100vh" }}>
      {loading ? (
        <Loader />
      ) : (
        <Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              pr: "2rem",
            }}
          >
            <Box sx={{ display: "flex" }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  padding: 3,
                  width: "240px",
                }}
              >
                <img
                  onClick={() => navigate(PathList._Dashboard)}
                  src={logo}
                  height={"100%"}
                  width={"100%"}
                  style={{ boxSizing: "border-box", cursor: "pointer" }}
                  alt="Logo"
                />
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <img
                  src={backIcon}
                  alt="Back Icon"
                  style={{ cursor: "pointer" }}
                  onClick={() => navigate(-1)}
                />
                <Typography sx={{ fontSize: "32px", color: "#D8D4FF" }}>
                  {isEditMode ? "Edit" : "Add"} News
                </Typography>
              </Box>
            </Box>
            <Button
              disabled={
                !formik?.values?.content?.replace(/<[^>]+>/g, "").trim() &&
                formik.dirty
              }
              type="submit"
              sx={{
                backgroundColor: "#7775fe",
                color: "#D8D4FF",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textTransform: "none",
                "&:hover": {
                  backgroundColor: "#7775fe",
                },
              }}
              onClick={formik.handleSubmit}
            >
              {isEditMode ? "Update" : "Add"}
            </Button>
          </Box>
          <Box>
            <Box
              sx={{ height: "calc(100vh - 115px)", overflow: "scroll", pb: 2 }}
            >
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Box
                  sx={{
                    width: { xs: "70%", md: "35%", lg: "25%" },
                    height: "100%",
                  }}
                >
                  <SingleImage
                    apiPreview={
                      typeof formik.values.image === "string"
                        ? BaseUrl + formik.values.image
                        : null
                    }
                    setImage={formik.setFieldValue}
                    name="image"
                  />
                  {formik.errors.image ? (
                    <Typography sx={{ color: "red", fontSize: "14px" }}>
                      {formik.errors.image}
                    </Typography>
                  ) : null}
                </Box>
              </Box>
              <Box sx={{ m: "0 auto", px: 3, maxWidth: "1440px" }}>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      color: "#D8D4FF",
                      maxWidth: "1440px",
                    }}
                  >
                    <form onSubmit={formik.handleSubmit}>
                      <label htmlFor="heading">News Heading</label>
                      <TextField
                        type="text"
                        id="heading"
                        name="heading"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.heading}
                        variant="outlined"
                        fullWidth
                        focused
                        sx={{
                          margin: "0.5rem 0 0 0",
                          ".MuiInputBase-root": {
                            borderRadius: "8px",
                            background: "#EAE8FF45",
                            color: "#D8D4FF",
                          },
                          ".MuiInputBase-root:hover": {
                            background: "#6831F6",
                          },
                          ".MuiOutlinedInput-input:focus": {
                            outline: "none",
                          },
                          fieldset: {
                            border: "none",
                          },
                        }}
                        placeholder="Enter news heading"
                      />
                      {formik.errors.heading ? (
                        <Typography sx={{ color: "red", fontSize: "14px" }}>
                          {formik.errors.heading}
                        </Typography>
                      ) : null}
                      <Box sx={{ my: 1 }}>
                        <label htmlFor="subtitle">News Sub-Title</label>
                        <TextField
                          type="text"
                          id="subtitle"
                          name="subtitle"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.subtitle}
                          variant="outlined"
                          fullWidth
                          focused
                          sx={{
                            margin: "0.5rem 0 0 0",
                            ".MuiInputBase-root": {
                              borderRadius: "8px",
                              background: "#EAE8FF45",
                              color: "#D8D4FF",
                            },
                            ".MuiInputBase-root:hover": {
                              background: "#6831F6",
                            },
                            ".MuiOutlinedInput-input:focus": {
                              outline: "none",
                            },
                            fieldset: {
                              border: "none",
                            },
                          }}
                          placeholder="Enter news sub-title"
                        />
                        {formik.errors.subtitle ? (
                          <Typography sx={{ color: "red", fontSize: "14px" }}>
                            {formik.errors.subtitle}
                          </Typography>
                        ) : null}
                      </Box>
                      <Box
                        sx={{
                          color: "#FFF",
                          width: "100%",
                          textAlign: "left",
                          borderRadius: "10px",
                          boxSizing: "border-box",
                          background: `rgba(234, 232, 255, 0.18)`,
                          mt: "15px",
                          "& .ql-container": {
                            minHeight: "200px",
                            maxHeight: "calc(100vh - 570px)",
                            overflow: "auto",
                            resize: "both",
                            background: "#EAE8FF18",
                            color: "#fff",
                            borderBottomLeftRadius: "10px",
                            borderBottomRightRadius: "10px",
                          },
                          "& .ql-toolbar": {
                            borderTop: "none",
                            borderLeft: "none",
                            borderRight: "none",
                            borderTopLeftRadius: "10px",
                            borderTopRightRadius: "10px",
                            zIndex: 999,
                            backgroundColor: "rgba(255, 255, 255, 0.2)",
                          },
                          "& .ql-toolbar .ql-picker": {
                            color: "#fff",
                          },
                          "& .ql-toolbar button": {
                            color: "#fff !important",
                          },
                          "& .ql-toolbar .ql-stroke": {
                            stroke: "#fff",
                          },
                          "& .ql-toolbar .ql-fill": {
                            color: "#fff !important",
                          },
                          "& .ql-toolbar .ql-picker-options": {
                            backgroundColor: "#333",
                            color: "#fff",
                          },
                          "& .ql-toolbar .ql-picker-label": {
                            color: "#fff !important",
                          },
                          "& .ql-toolbar button:focus": {
                            backgroundColor: "rgba(119, 82, 254, 1) !important",
                            color: "#fff !important",
                          },
                          ".ql-editor.ql-blank::before": {
                            color: "#ffffff75",
                            fontWeight: "500",
                          },
                        }}
                      >
                        <ReactQuill
                          placeholder="Content"
                          value={formik.values.content}
                          // ref={ref}
                          onChange={(content) => {
                            content = content.replace(
                              /(^<[^>]*>)([\s]*)/gm,
                              "$1"
                            );
                            formik.setFieldValue("content", content);
                          }}
                          modules={{
                            toolbar: [
                              [{ header: [1, 2, 3, 4, 5, 6, false] }],
                              ["bold", "italic", "underline"],
                              [{ list: "ordered" }, { list: "bullet" }],
                              [
                                { align: "right" },
                                { align: "center" },
                                { align: "justify" },
                                { align: "" },
                              ],
                              [
                                {
                                  color: [
                                    "black",
                                    "#D8D4FF",
                                    "gray",
                                    "silver",
                                    "maroon",
                                    "red",
                                    "purple",
                                    "green",
                                    "lime",
                                    "yellow",
                                    "navy",
                                    "blue",
                                    "teal",
                                    "aqua",
                                  ],
                                },
                                {
                                  background: [
                                    "transparent",
                                    "#f5f5f5", // Light Grey
                                    "#dcdcdc", // Gainsboro
                                    "#c0c0c0", // Silver
                                    "#a9a9a9", // Dark Grey
                                    "#808080", // Grey
                                    "#696969", // Dim Grey
                                    "#595959", // Dark Grey
                                    "#404040", // Black
                                    "#2f2f2f", // Dark Grey
                                    "#202020", // Dark Grey
                                    "#101010", // Black
                                    "#ff0000", // Red
                                    "#00ff00", // Green
                                    "#0000ff", // Blue
                                    "#ffff00", // Yellow
                                    "#ff00ff", // Magenta
                                    "#00ffff", // Cyan
                                    "#808080", // Gray
                                    "#800080", // Purple
                                    "#008000", // Green
                                    "#000080", // Navy
                                    "#808000", // Olive
                                    "#ffa500", // Orange
                                    "#ff69b4", // Pink
                                    "#ffdab9", // Peach
                                    "#fa8072", // Salmon
                                    "#a52a2a", // Brown
                                    "#008080", // Teal
                                    "#00ffff", // Cyan
                                    "#90ee90", // Light Green
                                    "#ffb6c1", // Pink
                                    "#fffaf0", // Floral White
                                    "#ffffe0", // Light Yellow
                                  ],
                                },
                              ],
                            ],
                          }}
                          className="termsCondtionContent"
                        />
                      </Box>{" "}
                      {formik.errors.content && formik.touched.content ? (
                        <Typography sx={{ color: "red", fontSize: "14px" }}>
                          {formik.errors.content}
                        </Typography>
                      ) : null}
                    </form>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default AddNewsScreen;
