const PathList = {
  _Login: "/",
  _Dashboard: "/dashboard",
  _User: "/user",
  _Miner: "/miners",
  _News: "/news",
  _Content: "/content",
  _Payment: "/payment",
  _NEWS_ADD: "/news/add",
};

export default PathList;
