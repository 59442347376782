import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

import logo from "../../Assets/image/sidebar/logout.svg";
import logoW from "../../Assets/image/sidebar/logout.png";

import PathList from "../../Common/PathList";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "#EAE8FF",
  boxShadow: 12,
  p: 4,
  borderRadius: "12px",
  outline: "none",
  // background: `linear-gradient(180deg, rgba(244, 242, 255, 0.064) 0%, rgba(244, 242, 255, 0.224) 120.79%)`,
};

export default function LogoutModal() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("loginInfo");
    handleClose();
    navigate(PathList._Login);
    toast.success("Logout successfully.", { toastId: "logout" });
  };

  return (
    <div>
      <Box className={"flexBox"}>
        <Button
          onClick={handleOpen}
          sx={{
            background: "#7752FE",
            color: "white",
            "&:hover": {
              background: "#7752FE70",
            },
            textTransform: "none",
            padding: "0.5rem 2rem",
            borderRadius: "10px",
          }}
        >
          <img
            src={logoW}
            style={{ height: "24px", width: "24px", objectFit: "cover" }}
            alt="Icon"
            srcSet=""
          />
          &nbsp; Logout
        </Button>
        {/* <ListItem disablePadding >
          <ListItemButton>
            <ListItemIcon>
              
            </ListItemIcon>
            <ListItemText primary={"Logout"} />
          </ListItemButton>
        </ListItem> */}
      </Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box className={"flexBox"}>
            <img
              src={logo}
              style={{
                height: "100px",
                width: "100px",
                objectFit: "contain",
              }}
              alt="Icon"
              srcSet=""
            />
          </Box>
          <Typography
            sx={{ textAlign: "center", padding: "1rem 0", fontWeight: "600", fontSize: "1.3rem" }}
            id="modal-modal-title"
            variant="h6"
            component="h2"
          >
            Are you sure ?
          </Typography>
          <Box
            className={"flexBox"}
            sx={{ justifyContent: "space-between", gap: "1rem" }}
          >
            <Button
              variant="outlined"
              sx={{
                width: "100%",
                textTransform: "none",
                borderRadius: "8px",
                fontSize: "18px",
                border: '1px solid #05001B99',
                color: "#05001B99",
                fontWeight: "600",
                padding: '8px 14px',
                "&:hover": {
                  border: '1px solid #05001B99',
                  backgroundColor: "#fff",
                },
              }}
              onClick={handleClose}
            >
              Cancle
            </Button>
            <Button
              variant="outlined"
              sx={{
                width: "100%",
                textTransform: "none",
                borderRadius: "8px",
                background: "#7752fe",
                fontSize: "18px",
                border: '1px solid #7752fe',
                color: "#fff",
                fontWeight: "600",
                padding: '8px 14px',
                "&:hover": {
                  border: '1px solid #7752fe',
                  backgroundColor: "#7752fe",
                },
              }}
              onClick={handleLogout}
            >
              Confirm
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
