import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import backIcon from "../../../Assets/image/back.png";
import { useNavigate } from "react-router-dom";
import ActiveMinorsComponent from "./ActiveMinorsComponent";
import PurchaseHistoryComponent from "./PurchaseHistoryComponent";
import WithdrawComponent from "./WithdrawComponent";

function UserDetailComponent() {
  const navigate = useNavigate();
  const [item, setItem] = useState(0);
  const headers = [
    { name: "Active Minors", value: 0 },
    { name: "Purchase History", value: 1 },
    { name: "Withdraw", value: 2 },
  ];
  return (
    <Box sx={{ width: "100%", height: "100%", p: 2 }}>
      <Box sx={{ display: "flex", alignItems: "center",gap:1 }}>
        <img
          src={backIcon}
          alt=""
          srcSet=""
          style={{ cursor: "pointer" }}
          onClick={() => navigate(-1)}
        />
        <Typography sx={{ fontSize: "32px" }}>User Details</Typography>
      </Box>
      <Box
        sx={{
          color: "#D8D4FF",
          height: { xs: "calc(100% - 135px)", md: "calc(100% - 135px)" },
        }}
      >
        <Box
          sx={{
            borderBottom: "1px solid #9985FF80",
            display: "flex",
            flexWrap: "nowrap",
            overflow: "scroll",
            padding: "0.5rem",
          }}
        >
          {headers.map((x) => (
            <Box
              sx={{
                width: "180px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                overflow: "scroll",
                cursor: "pointer",
                textAlign: "center",
                padding: "11px",
                background: item === x?.value && "#9985FF1F",
                borderRadius: "11px",
                color: item === x?.value && "#9985FF",
                "&:hover": {
                  // background: "#9985FF1F",
                  color: "#9985FF",
                },
              }}
              onClick={() => setItem(x.value)}
            >
              {x.name}
            </Box>
          ))}
        </Box>
        <Box sx={{ height: "calc(100% - 15px)", color: "red", pt: 2 }}>
          {item === 0 && (
            <Box sx={{ height: "100%" }}>
              <ActiveMinorsComponent />
            </Box>
          )}
          {item === 1 && (
            <Box sx={{ height: "100%" }}>
              <PurchaseHistoryComponent />
            </Box>
          )}
          {item === 2 && (
            <Box sx={{ height: "100%" }}>
              <WithdrawComponent />
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
}

export default UserDetailComponent;
