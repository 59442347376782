import { Box, colors, Typography } from "@mui/material";
import React from "react";
import logo from "../../../Assets/image/logo.svg";
import linkIcon from "../../../Assets/image/user/link.png";
import appIcon from "../../../Assets/image/user/yes.png";
import rejectIcon from "../../../Assets/image/user/no.png";
import PathList from "../../../Common/PathList";
import { Link, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { BaseUrl } from "../../../Common/Constant";
import { POST_API } from "../../../Common/Api/Function";
import apiEndpoints from "../../../Common/Api/apiEndpoints";
import { toast } from "react-toastify";

const drawerWidth = 240;
function UserDetailsSideBar({ data, handleData }) {
  const navigate = useNavigate();
  const { id } = useParams();

  const handleRequest = async (status) => {
    const f = new FormData();
    f.append("status", status);
    try {
      let res = await POST_API(
        `${apiEndpoints.handlebankdetails}/${data?.bank_details?.id}`,
        f
      );
      toast.success(res.m);
      handleData();

      if (res.s) {
        let data = new FormData();
        data.append("uids", id);
        data.append("title", "MinerX");
        data.append(
          "message",
          status === 1
            ? "Your bank details has been approved."
            : "Your bank details has been rejected."
        );
        data.append("type", "1");
        data.append("type_id", data?.bank_details?.id);
        data.append("is_store", "1");
      await POST_API(apiEndpoints.sendnotification, data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Box
      sx={{
        background: `linear-gradient(180deg, #05001B 0%, #382581 75.32%, #3F179B 104.39%)`,
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        width: drawerWidth,
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "center", padding: 3 }}>
        <img
          onClick={() => navigate(PathList._Dashboard)}
          src={logo}
          height={"100%"}
          width={"100%"}
          style={{ boxSizing: "border-box", cursor: "pointer" }}
          alt="Logo"
          srcSet=""
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "start",
          alignItems: "start",
          flexDirection: "column",
          gap: "1rem",
          p: 2,
          height: "calc(100vh - 115px)",
          overflowY: "scroll",
        }}
      >
        <Box>
          <Typography
            sx={{
              color: "#9985FF",
              fontWeight: "700",
              fontSize: "13px",
            }}
          >
            FULL NAME
          </Typography>
          <Typography
            sx={{
              color: "#D8D4FF",
              fontWeight: "500",
              fontSize: "16px",
            }}
          >
            {data?.f_name + " " + data?.l_name}
          </Typography>
        </Box>
        <Box>
          <Typography
            sx={{
              color: "#9985FF",
              fontWeight: "700",
              fontSize: "13px",
            }}
          >
            EMAIL
          </Typography>
          <Typography
            sx={{
              textWrap: "pretty",
              color: "#D8D4FF",
              fontWeight: "500",
              fontSize: "16px",
            }}
          >
            <Link style={{color: "#D8D4FF",textDecoration:'none'}} to={`mailto:${data?.email}`}>
            {data?.email}
            </Link>
          </Typography>
        </Box>
        <Box>
          
          <Typography
            sx={{
              color: "#9985FF",
              fontWeight: "700",
              fontSize: "13px",
            }}
          >
            DOB
          </Typography>
          <Typography
            sx={{
              color: "#D8D4FF",
              fontWeight: "500",
              fontSize: "16px",
            }}
          >
            {moment(data?.dob).format("DD-MM-YYYY")}
          </Typography>
        </Box>
        <hr style={{ border: "1px solid #FFFFFF66", width: "100%" }} />
        <Box>
          {data?.bank_details ? (
            <>
              {data?.bank_details?.status === -1? (
                <Box
                  sx={{
                    color: "#fff",
                    borderRadius: "10px",
                    p: 1.8,
                    bgcolor: "red",
                    // fontSize:'16px'
                  }}
                >
                  Bank request Rejected
                </Box>
              ) : data?.bank_details?.status === 1 ? (
                <Box
                  sx={{
                    bgcolor: "green",
                    borderRadius: "10px",
                    p: 1.8,
                    color: "#fff",
                  }}
                >
                  Bank request approved
                </Box>
              ) : (
                data?.bank_details?.status === 0 && (
                  <>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "start",
                        flexDirection: "column",
                        gap: "1rem",
                      }}
                    >
                      {" "}
                      <Box sx={{ width: "100%" }}>
                        <Typography
                          sx={{
                            color: "#9985FF",
                            fontWeight: "700",
                            fontSize: "13px",
                          }}
                        >
                          DOC
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            gap: "0.5rem",
                            background: " #FFFFFF0F",
                            padding: "12px",
                            borderRadius: "12px",
                            cursor: "pointer",
                            color: "#D8D4FF",
                            mt: "5px",
                            width: "100%",
                            alignItems: "center",
                          }}
                          onClick={() =>
                            window.open(
                              `${BaseUrl}${data?.bank_details?.image}`
                            )
                          }
                        >
                          <img
                            src={linkIcon}
                            alt="linkIcon"
                            srcSet=""
                            height={"18px"}
                            width={"18px"}
                          />
                          <Typography
                            sx={{
                              color: "#D8D4FF",
                              fontWeight: "500",
                              fontSize: "16px",
                            }}
                          >
                            Attachment
                          </Typography>
                        </Box>
                      </Box>
                      <Box>
                        <Typography
                          sx={{
                            color: "#9985FF",
                            fontWeight: "700",
                            fontSize: "13px",
                          }}
                        >
                          ADDRESS
                        </Typography>
                        <Typography
                          sx={{
                            color: "#D8D4FF",
                            fontWeight: "500",
                            fontSize: "16px",
                          }}
                        >
                          {data?.bank_details?.recipient_address}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography
                          sx={{
                            color: "#9985FF",
                            fontWeight: "700",
                            fontSize: "13px",
                          }}
                        >
                          Bank Account Number
                        </Typography>
                        <Typography
                          sx={{
                            color: "#D8D4FF",
                            fontWeight: "500",
                            fontSize: "16px",
                          }}
                        >
                          {data?.bank_details?.account_number}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography
                          sx={{
                            color: "#9985FF",
                            fontWeight: "700",
                            fontSize: "13px",
                          }}
                        >
                          Sort Code
                        </Typography>
                        <Typography
                          sx={{
                            color: "#D8D4FF",
                            fontWeight: "500",
                            fontSize: "16px",
                          }}
                        >
                          {data?.bank_details?.sort_code}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography
                          sx={{
                            color: "#9985FF",
                            fontWeight: "700",
                            fontSize: "13px",
                          }}
                        >
                          IBAN
                        </Typography>
                        <Typography
                          sx={{
                            color: "#D8D4FF",
                            fontWeight: "500",
                            fontSize: "16px",
                          }}
                        >
                          {data?.bank_details?.iban}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          padding: "14px",
                          background: "#05001BB2",
                          // border: "1px solid #FFFFFF24",
                          display: "flex",
                          gap: "0.7rem",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: "11px",
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#D8D4FF",
                            fontWeight: "600",
                            fontSize: "13px",
                          }}
                        >
                          User Banking Request:
                        </Typography>
                        <img
                          onClick={() => handleRequest(1)}
                          src={appIcon}
                          alt="approve"
                          srcSet=""
                          style={{
                            height: "24px",
                            width: "24px",
                            cursor: "pointer",
                          }}
                          className="ImgHover"
                        />{" "}
                        <img
                          onClick={() => handleRequest(-1)}
                          src={rejectIcon}
                          alt="reject"
                          srcSet=""
                          style={{
                            height: "24px",
                            width: "24px",
                            cursor: "pointer",
                          }}
                          className="ImgHover"
                        />
                      </Box>{" "}
                    </Box>
                  </>
                )
              )}
            </>
          ) : (
            <Typography
              sx={{
                color: "#D8D4FF",
                fontWeight: "600",
                fontSize: "18px",
                padding: 2,
                borderRadius: "10px",
                background: "#FFFFFF24",
              }}
            >
              Bank details not submitted
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
}

export default UserDetailsSideBar;
