import React, { useState, useEffect, useRef } from "react";
import { Box, Typography } from "@mui/material";
import Loader from "../../Common/Loader";
import viewIcon from "../../Assets/image/user/view.png";
import blockIcon from "../../Assets/image/user/block.png";
import ActiveIcon from "../../Assets/image/user/Active.png";
import { DataListingComponent } from "../../Component/DataList";
import FilterComponent from "../../Component/FilterComponent";
import { useNavigate } from "react-router-dom";
import PathList from "../../Common/PathList";
import CustomSearch from "../../Component/SearchField";
import { GET_API, POST_API } from "../../Common/Api/Function";
import apiEndpoints from "../../Common/Api/apiEndpoints";
import ConfirmationModal from "../../Component/ConfirmationModal";
import { toast } from "react-toastify";
import moment from "moment";
import debounce from "lodash.debounce";

const PAGE_SIZE_OPTIONS = [15];

function UserManagement() {
  const [loading, setLoading] = useState(true);
  const [loader, setLoader] = useState(true);
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [searchTerm, setSearchterm] = useState("");
  const [prms, setPrms] = useState([-1, -1, 1]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(PAGE_SIZE_OPTIONS[0]);
  const [totalData, setTotalData] = useState(0);

  const columns = [
    {
      headerClassName: "super-app-theme--header",
      field: "rowid",
      headerName: "Sr No.",
      width: 70,
      headerAlign: "center",
      renderCell: (params) => {
        return params.row.rowid + page * rowsPerPage;
      },
    },
    {
      headerClassName: "super-app-theme--header",
      field: "f_name",
      headerName: "First Name",
      flex: 1,
      minWidth: 150,
      headerAlign: "center",
    },
    {
      headerClassName: "super-app-theme--header",
      field: "l_name",
      headerName: "Last Name",
      flex: 1,
      minWidth: 150,
      headerAlign: "center",
    },
    {
      headerClassName: "super-app-theme--header",
      field: "dob",
      headerName: "DOB",
      width: 150,
      headerAlign: "center",
      renderCell: (params) => (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <Typography sx={{ fontSize: "14px" }}>
            {moment(params?.row?.dob)?.format("DD/MM/YYYY")}
          </Typography>
        </Box>
      ),
    },
    {
      headerClassName: "super-app-theme--header",
      field: "email",
      headerName: "Email",
      flex: 1,
      minWidth: 280,
      headerAlign: "center",
    },
    {
      headerClassName: "super-app-theme--header",
      field: "is_Active",
      headerName: "Action",
      width: 130,
      headerAlign: "center",
      renderCell: (e) => (
        <Box
          sx={{
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "2rem",
          }}
        >
          <img
            src={viewIcon}
            alt="icon"
            srcSet=""
            height={24}
            width={24}
            onClick={() => navigate(`${PathList._User}/${e?.id}`)}
            className="ImgHover"
          />
          <ConfirmationModal
            btnImg={e?.row?.status === 1 ? ActiveIcon : blockIcon}
            handleClick={(status) => handleBlock(e?.id, status)}
            cnfBtnTitle={e?.row?.status !== 1 ? "Unblock" : "Block"}
            status={e?.row?.status == 1 ? 0 : 1}
          />
        </Box>
      ),
    },
  ];

  const handleBlock = async (id, status) => {
    const f = new FormData();

    try
    {
      f.append("user_id", id);
      f.append("status", status);

      let res = await POST_API(apiEndpoints.blockuser, f);
      if (res?.s === 1)
      {
        handleUser();
        toast.success("Success");
      }
    } catch (error)
    {
      console.log(error);
    }
  };

  const handleMenuItemClick = async (value) => {
    setPrms(value);
    await handleUser(value, 0);
    return true;
  };

  const handleUser = async (
    prms = [-1, -1, 1],
    page = 0,
    search = "",
    prevSearch = "",
    pageSize = PAGE_SIZE_OPTIONS[0]
  ) => {
    try
    {
      setLoader(true);
      let newPage = page;
      let count = newPage * pageSize;

      if (search !== prevSearch && search)
      {
        newPage = 0;
        setPage(0);
      }

      let res = await GET_API(apiEndpoints.getalluser(count, prms));
      if (res.r.length > 0)
      {
        const Rowdata = res?.r?.map((data, i) => ({
          rowid: i + 1,
          ...data,
        }));
        setData(Rowdata);
        page === 0 && setTotalData(res.total);
      } else
      {
        if (newPage === 0)
        {
          setData([]);
        }
        setTotalData(0);
      }

      setLoading(false);
      setLoader(false);
    } catch (error)
    {
      setLoading(false);
      console.log(error);
    }
  };

  const handleSearch = (event) => {
    setSearchterm(event.target.value);
  };

  const debounceFunction = debounce(
    (status, page, searchTerm, prevSearch) =>
      handleUser(status, page, searchTerm, prevSearch),
    300
  );

  let searchRef = useRef(searchTerm);

  useEffect(() => {
    if (searchTerm?.length)
    {
      debounceFunction(
        [...prms, `%25${searchTerm}%25`, `%25${searchTerm}%25`],
        page,
        searchTerm,
        searchRef.current
      );
    } else
    {
      debounceFunction(prms, page);
    }

    searchRef.current = searchTerm;
    return () => {
      debounceFunction.cancel();
    };
  }, [searchTerm, page]);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Box sx={{ height: "calc(100vh - 32px)", width: "100%" }}>
      <Box sx={{ p: "24px 0" }}>
        <Typography
          sx={{
            fontSize: { xs: "20px", md: "32px" },
            color: "#D8D4FF",
          }}
        >
          User Management
        </Typography>
      </Box>
      <Box
        sx={{
          height: "50px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: "10px",
        }}
      >
        <CustomSearch
          onChange={handleSearch}
          placeholder={"Search"}
          value={searchTerm}
        />
        <FilterComponent
          handlefunction={handleMenuItemClick}
          options={[
            {
              id: "1",
              label: "All",
              value: [-1, -1, 1],
            },
            {
              id: "2",
              label: "Active",
              value: [1, 1, 1],
            },
            {
              id: "3",
              label: "Blocked",
              value: [0, 0, 1],
            },
          ]}
        />
      </Box>
      <Box sx={{ height: "calc(100vh - 210px)" }}>
        {loading ? (
          <Loader />
        ) : (
          <>
            <DataListingComponent
              data={data??[]}
              loading={loader}
              totalData={totalData}
              page={page}
              columns={columns}
              rowsPerPage={rowsPerPage}
              PAGE_SIZE_OPTIONS={PAGE_SIZE_OPTIONS[0]}
              handlePageChange={handlePageChange}
              handleRowsPerPageChange={handleRowsPerPageChange}
            />
          </>
        )}
      </Box>
    </Box>
  );
}

export default UserManagement;
