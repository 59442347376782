/* eslint-disable react/style-prop-object */
import { Box } from "@mui/material";
import React from "react";
import Lottie from "react-lottie";
import loader from "./bitcoin.json";
// import loader from "./MiningLoader.json";

function Loader() {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    // animationData: loader,
    animationData: loader,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        overflow: "hidden",
        boxSizing: "border-box",
      }}
    >
      {/* <CircularProgress style={{ color: "#7752fe" }} /> */}
      <Lottie options={defaultOptions} height={100} width={100} />
    </Box>
  );
}

export default Loader;
