import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PathList from "../Common/PathList";

function PrivateRoute({ children }) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    try
    {
      const userInfo = JSON.parse(localStorage.getItem("loginInfo"));
      if (userInfo)
      {
        setIsAuthenticated(true);
      } else
      {
        navigate(PathList._Login);
      }
    } catch (error)
    {
      navigate(PathList._Login);
    } finally
    {
      setIsLoading(false);
    }
  }, [navigate]);

  if (isLoading)
  {
    // return children;
    return null;
  }

  // return isAuthenticated ? children : children;
  return isAuthenticated ? children : null;
}

export default PrivateRoute;
 