import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import UserDetailComponent from "./UserDetailComponent";
import UserDetailsSideBar from "./UserDetailsSideBar";
import apiEndpoints from "../../../Common/Api/apiEndpoints";
import { POST_API } from "../../../Common/Api/Function";
import { useParams } from "react-router-dom";
import Loader from "../../../Common/Loader";

const UserDetailsScreen = () => {
  const [userData, setUserData] = useState(false);
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  const handleData = async () => {
    let data = new FormData();
    data.append(
      "select",
      "user.*,countries.country_name,user_auth.apikey,user_auth.token"
    );
    data.append(
      "from",
      "user LEFT JOIN countries on user.country_id = countries.id INNER JOIN user_auth ON user_auth.user_id = user.id"
    );
    data.append("arg", `user.id = ${id}`);
    data.append("limit", "1");
    data.append(
      "childEntity",
      '{"bank_details":{"select":"*","from":"user_bank","arg":"user_id = ?","skip":0,"limit":1,"prms":[":;id"]}}'
    );

    try {
      let res = await POST_API(apiEndpoints.getUserById, data);
      setUserData(res.r);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    handleData();
  }, []);
  return (
    <Box
      sx={{
        width: "100%",
        height: "100vh",
        display: "flex",
        background: "#05001B",
      }}
    >
      {loading ? (
        <Loader />
      ) : (
        <>
          <UserDetailsSideBar data={userData} handleData={handleData}/>
          <Box
            sx={{
              width: "calc(100% - 250px)",
              height: "100vh",
              color: "#D8D4FF",
              p: 2,
            }}
          >
            <UserDetailComponent />
          </Box>
        </>
      )}
    </Box>
  );
};

export default UserDetailsScreen;
