import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Box } from "@mui/material";
import { POST_API } from "../../Common/Api/Function";
import apiEndpoints from "../../Common/Api/apiEndpoints";
import { toast } from "react-toastify";

export default function PaymentStatusMenu({
  setOpen,
  status,
  handleUser,
  id,
  uid,
  setPid,
  setUserId,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleProgress = async () => {
    let data = new FormData();
    data.append("withdraw_status", 1);
    try
    {
      let res = await POST_API(`${apiEndpoints.refrenceid}/${id}`, data);
      if (res.s)
      {
        let data = new FormData();
        data.append("uids", uid);
        data.append("title", "MinerX");
        data.append("message", "Your withdraw request is in process.");
        data.append("type", "1");
        data.append("type_id", id);
        data.append("is_store", "1");
        await POST_API(apiEndpoints.sendnotification, data);

        handleUser();
        toast.success("Success");
      }
      handleClose();
    } catch (error)
    {
      console.log(error);
    }
  };
  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={status === 2 ? null : handleClick}
        // onClick={status === 2 ? handleClick : handleClick}
        style={{
          padding: "5px 10px",
          borderRadius: "5px",
          textTransform: "none",
          background:
            status === 0
              ? "grey"
              : status === 1
                ? "blue"
                : status === 2 && "green",
          color: "#fff",
        }}
      >
        {status === 0
          ? "Pending"
          : status === 1
            ? "In Progress"
            : status === 2 && "Paid"}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem disabled={status === 1} onClick={handleProgress}>
          <Box>
            <span
              style={{
                padding: "5px 10px",
                borderRadius: "5px",
                background: "#FFFFFF24",
              }}
            >
              In Progress
            </span>
          </Box>
        </MenuItem>
        <MenuItem
          disabled={status === 0}
          onClick={() => {
            setUserId(uid);
            handleClose();
            setOpen(true);
            setPid(id);
          }}
        >
          <Box>
            <span
              style={{
                padding: "5px 10px",
                borderRadius: "5px",
                background: "#FFFFFF24",
              }}
            >
              Paid
            </span>
          </Box>
        </MenuItem>
      </Menu>
    </div>
  );
}
