import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { useState } from "react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 420,
  background: "#EAE8FF",
  borderRadius: "12px",
  boxShadow: 24,
  p: 3,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "1rem",
  flexDirection: "column",
};

export default function MinerEditModal({
  setOpen,
  open,
  data,
  handleEdit,
  setModalData,
}) {
  const handleClose = () => setOpen(false);
  // Function to handle number inputs with two decimal places
  const handleInputChange = (e, field) => {
    const value = e.target.value;
    // Regular expression to allow only up to two decimal places
    const formattedValue = value.match(/^\d+(\.\d{0,2})?$/)
      ? value
      : data[field];
    console.log(data[field])
    setModalData({ ...data, [field]: formattedValue });
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          ".MuiBackdrop-root": {
            background: "rgba(0, 0, 0, 0.05)",
          },
        }}
      >
        <Box sx={style}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "1rem",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "right",
                width: "100%",
              }}
            >
              <CancelOutlinedIcon
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  handleClose();
                  setModalData({});
                }}
              />
            </Box>

            <label htmlFor="roi" style={{ width: "100%" }}>
              <p
                style={{
                  color: "#05001B",
                  fontWeight: "500",
                  fontSize: "16px",
                }}
              >
                Annual ROI %
              </p>
              <input
                onChange={(e) => handleInputChange(e, "roi")}
                onInput={(e) => handleInputChange(e, "roi")}
                type="number"
                value={data.roi}
                name="roi"
                id="roi"
                autoFocus={true}
                placeholder="Enter"
                style={{
                  padding: "14px 18px",
                  border: "1px solid #BBB1FF",
                  background: "#D8D4FF",
                  borderRadius: "11px",
                  outline: "none",
                  color: "#05001B",
                  width: "100%",
                  marginTop: "5px",
                }}
              />
            </label>
            <label htmlFor="maintenance" style={{ width: "100%" }}>
              <p
                style={{
                  color: "#05001B",
                  fontWeight: "500",
                  fontSize: "16px",
                }}
              >
                Current Maintenance %
              </p>
              <input
                onChange={(e) => handleInputChange(e, "maintenance")}
                onInput={(e) => handleInputChange(e, "maintenance")}
                type="number"
                value={data.maintenance}
                name="maintenance"
                id="maintenance"
                placeholder="Enter"
                style={{
                  padding: "14px 18px",
                  border: "1px solid #BBB1FF",
                  background: "#D8D4FF",
                  borderRadius: "11px",
                  outline: "none",
                  color: "#05001B",
                  width: "100%",
                  marginTop: "5px",
                }}
              />
            </label>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              mt: "0.5rem",
            }}
          >
            <Button
              sx={{
                background: "#7752FE",

                color: "#D8D4FF",
                padding: "8px",
                fontSize: "16px",
                fontWeight: "600",
                textTransform: "none",
                "&:hover": {
                  backgroundColor: "#7775fe",
                },
                width: "150px",
                borderRadius: "8px",
              }}
              onClick={() =>
                handleEdit({
                  roi: data.roi,
                  maintenance: data.maintenance,
                  id: data.id,
                  prevRoi: data.prevRoi,
                  prevMaintainance: data.prevMaintainance,
                })
              }
            >
              Submit
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
