import React, { useEffect, useState } from "react";
import { DataListingComponent } from "../../../Component/DataList";
import { Box } from "@mui/material";
import viewIcon from "../../../Assets/image/user/edit.png";
import { GET_API, POST_API } from "../../../Common/Api/Function";
import apiEndpoints from "../../../Common/Api/apiEndpoints";
import { useParams } from "react-router-dom";
import Loader from "../../../Common/Loader";
import { toast } from "react-toastify";
import MinerEditModal from "../../../Component/MinerEditModal";
const PAGE_SIZE_OPTIONS = [10];
const ActiveMinorsComponent = () => {
  const [loading, setLoading] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [modalData, setModalData] = useState({});
  const [page, setPage] = useState(0);
  const [open, setOpen] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(PAGE_SIZE_OPTIONS[0]);
  const [totalData, setTotalData] = useState(0);
  const [data, setData] = useState([]);
  const handlePageChange = (e, newPage) => {
    setPage(newPage);
  };
  const { id } = useParams();

  const handleRowsPerPageChange = (e) => {
    setRowsPerPage(parseInt(e.target.value, 10));
    setPage(0);
  };

  const handleEdit = async (d) => {
    const formData = new FormData();
    formData.append("roi", d?.roi);
    formData.append("maintenance", d.maintenance);
    try
    {
      let res = await POST_API(apiEndpoints.updateminer(d.id), formData);
      if (res)
      {
        handleData();
        toast.success("Success.");
        setOpen(false);
      }
    } catch (error)
    {
      console.log(error);
      setOpen(false);
    }
  };

  const columns = [
    {
      headerClassName: "super-app-theme--header",
      field: "rowid",
      headerName: "#",
      width: 70,
      headerAlign: "center",
      renderCell: (params) => {
        return params.row.rowid + page * rowsPerPage;
      },
    },
    {
      headerClassName: "super-app-theme--header",
      field: "level",
      headerName: "Level/TH",
      flex: 1,
      minWidth: 200,
      headerAlign: "center",
    },
    {
      headerClassName: "super-app-theme--header",
      field: "th",
      headerName: "W/TH",
      flex: 1,
      minWidth: 200,
      headerAlign: "center",
      renderCell: (e) => e.row.energy + "W/TH",
    },
    {
      headerClassName: "super-app-theme--header",
      field: "days_active",
      headerName: "Days Active",
      width: 200,
      headerAlign: "center",
      renderCell: (e) => {
        function convertDaysToYears(days) {
          const years = Math.floor(days / 365);
          const remainingDays = days % 365;

          let result = '';
          if (years > 0)
          {
            result += `${years} yr`;
          }
          if (remainingDays > 0)
          {
            if (years > 0)
            {
              result += ' ';
            }
            result += `${remainingDays} days`;
          }

          return result || '0 days';
        }
        return convertDaysToYears(e?.row?.days_active)
      }

    },
    {
      headerClassName: "super-app-theme--header",
      field: "roi",
      headerName: "Annual ROI %",
      width: 200,
      headerAlign: "center",
      renderCell: (e) => e.row.roi + "%",
    },
    {
      headerClassName: "super-app-theme--header",
      field: "maintenance",
      headerName: "Current maintenance %",
      width: 200,
      headerAlign: "center",
      renderCell: (e) => e.row.maintenance + "%",
    },

    {
      headerClassName: "super-app-theme--header",
      headerName: "Action",
      width: 150,
      headerAlign: "center",
      renderCell: (cell) => (
        <Box
          sx={{
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "1rem",
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              onClick={() => {
                setOpen(true);
                setModalData({
                  ...cell.row,
                  prevRoi: cell.row.roi.toString(),
                  prevMaintainance: cell.row.maintenance.toString(),
                });
              }}
              className="ImgHover"
              src={viewIcon}
              alt="icon"
              srcSet=""
              height={24}
              width={24}
            />
          </Box>
        </Box>
      ),
    },
  ];

  const handleData = async (count = 0) => {
    let data = new FormData();
    data.append("from", "get_miner_purchase");
    data.append(
      "arg",
      `status = 1 and user_id = ${id} and payment_status = 1`
    );
    // data.append("prms", "[1,1]");
    data.append("skip", "0");
    data.append("limit", "30");
    data.append(
      "childEntity",
      '{"user_details":{"select":"id,f_name,l_name,profile_img","from":"user","arg":"id = ?","limit":1,"prms":[":;user_id"]}}'
    );
    data.append("total", "1");
    try
    {
      setLoading(true);
      let res = await POST_API(apiEndpoints.getUserById, data);
      const Rowdata = res?.r?.map((data, i) => ({
        rowid: i + 1,
        ...data,
      }));
      setData(Rowdata);
      // setData(res?.r);
      count === 0 && setTotalData(res.total)
      setIsDataLoading(false);
      setLoading(false);
    } catch (error)
    {
      console.log(error);
      setLoading(false);
      setIsDataLoading(false);
    }
  };

  useEffect(() => {
    handleData();
  }, []);
  return isDataLoading ? (
    <Loader />
  ) : (
    <>
      <DataListingComponent
        data={data ?? []}
        loading={loading}
        PAGE_SIZE_OPTIONS={PAGE_SIZE_OPTIONS[0]}
        columns={columns}
        totalData={totalData}
        page={page}
        rowsPerPage={rowsPerPage}
        handlePageChange={handlePageChange}
        handleRowsPerPageChange={handleRowsPerPageChange}
      />
      <MinerEditModal
        open={open}
        data={modalData}
        setModalData={setModalData}
        setOpen={setOpen}
        handleEdit={handleEdit}
      />
    </>
  );
};

export default ActiveMinorsComponent;
