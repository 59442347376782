import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import MenuIcon from "@mui/icons-material/Menu";
import logo from "../../Assets/image/logo.svg";
import { useState } from "react";
import { MenuList } from "./Sidebar";
import { useLocation, useNavigate } from "react-router-dom";
import { AppBar, Toolbar } from "@mui/material";
import LogoutModal from "./LogoutModal";
import PathList from "../../Common/PathList";

const drawerWidth = 240;

function Layout({ element }) {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };

  const drawer = (
    <Box
      sx={{
        background: `linear-gradient(180deg, #05001B 0%, #382581 75.32%, #3F179B 104.39%)`,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "100vh",
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "center", padding: 3 }}>
        <img
          onClick={() => navigate(PathList._Dashboard)}
          src={logo}
          height={"100%"}
          width={"100%"}
          style={{ boxSizing: "border-box", cursor: "pointer" }}
          alt="Logo"
          srcSet=""
        />
      </Box>
      <Box
        sx={{ height: "calc(100vh - 170px)", overflow: "scroll" }}
        className="sideBar hideScrollBar"
      >
        <List sx={{ padding: "0 0.75rem" }}>
          {MenuList.map(({ icon, title, url }, index) => {
            const isActive =
              location.pathname === `/${title.toLocaleLowerCase()}`;
            return (
              <ListItem
                sx={{
                  color: isActive ? "white" : "white",
                  borderRadius: "10px",
                  mb: "0.5rem",
                  background: isActive ? "#7752FE" : null,
                  "&:hover": {
                    background: "#7752FE70",
                  },
                }}
                key={index}
                disablePadding
                onClick={() => navigate(url)}
              >
                <ListItemButton>
                  <ListItemIcon sx={{ minWidth: "40px" }}>
                    <img
                      src={icon}
                      style={{
                        height: "24px",
                        width: "24px",
                        objectFit: "cover",
                      }}
                      alt="Icon"
                      srcSet=""
                    />
                  </ListItemIcon>
                  <ListItemText primary={title} />
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
      </Box>

      <Box sx={{ height: "80px" }}>
        <LogoutModal />
      </Box>
    </Box>
  );

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          display: { sm: "none" },
        }}
      >
        <Toolbar sx={{ background: "grey" }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth } }}
        aria-label="mailbox folders"
      >
        {/* Sidebar code for mobile screen */}
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onTransitionEnd={handleDrawerTransitionEnd}
          onClose={handleDrawerClose}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "block", sm: "none" },

            "& .MuiDrawer-paper": {
              border: "none",
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        {/* SideBar code on full screen */}
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              border: "none",
            },
          }}
          open
        >
          {/* <Toolbar /> */}

          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          p: "0 32px",
          width: `calc(100%  - ${drawerWidth}px)`,
          height: "100vh",
          boxSizing: "border-box",
          background: "#05001B",
        }}
      >
        {element}
      </Box>
    </Box>
  );
}

export default Layout;
