import PathList from "../../Common/PathList";
import dashIcon from "../../Assets/image/sidebar/Dashboard.svg";
import UserIcon from "../../Assets/image/sidebar/User.svg";
import MinerIcon from "../../Assets/image/sidebar/Miners.svg";
import PaymentIcon from "../../Assets/image/sidebar/Payment.svg";
import ContentIcon from "../../Assets/image/sidebar/Content.svg";
import NewsIcon from "../../Assets/image/sidebar/News.svg";

export const MenuList = [
  {
    id: 1,
    title: "Dashboard",
    url: PathList._Dashboard,
    icon: dashIcon,
  },
  {
    id: 2,
    title: "User",
    url: PathList._User,
    icon: UserIcon,
  },
  {
    id: 2,
    title: "Miners",
    url: PathList._Miner,
    icon: MinerIcon,
  },
  {
    id: 3,
    title: "Payment",
    url: PathList._Payment,
    icon: PaymentIcon,
  },
  {
    id: 4,
    title: "News",
    url: PathList._News,
    icon: NewsIcon,
  },
  {
    id: 4,
    title: "Content",
    url: PathList._Content,
    icon: ContentIcon,
  },
];
