import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import logo from "../Assets/image/confirm.svg";
import { useState } from "react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "#EAE8FF",
  boxShadow: 12,
  p: 4,
  borderRadius: "12px",
  outline: "none",
};

export default function ConfirmationModal({
  btnImg,
  handleClick,
  cnfBtnTitle,
  status,
}) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Box className={"flexBox"}>
        <img
          src={btnImg}
          style={{ height: "24px", width: "24px", objectFit: "cover" }}
          alt="Icon"
          srcSet=""
          onClick={handleOpen}
          className="ImgHover"
        />
      </Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box className={"flexBox"}>
            <img
              src={logo}
              style={{
                height: "100px",
                width: "100px",
                objectFit: "contain",
              }}
             
              alt="Icon"
              srcSet=""
            />
          </Box>
          <Typography
            sx={{
              textAlign: "center",
              padding: "1rem 0",
              fontWeight: "600",
              fontSize: "1.3rem",
            }}
            id="modal-modal-title"
            variant="h6"
            component="h2"
          >
            {`Are you sure you want to ${cnfBtnTitle.toLowerCase()}?`}
          </Typography>
          <Box
            className={"flexBox"}
            sx={{ justifyContent: "space-between", gap: "1rem" }}
          >
            <Button
              variant="outlined"
              sx={{
                width: "100%",
                textTransform: "none",
                borderRadius: "8px",
                fontSize: "18px",
                border: "1px solid #05001B99",
                color: "#05001B99",
                fontWeight: "600",
                padding: "8px 14px",
                "&:hover": {
                  border: "1px solid #05001B99",
                  backgroundColor: "#fff",
                },
              }}
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              variant="outlined"
              sx={{
                width: "100%",
                textTransform: "none",
                borderRadius: "8px",
                background: "#7752fe",
                fontSize: "18px",
                border: "1px solid #7752fe",
                color: "#fff",
                fontWeight: "600",
                padding: "8px 14px",
                "&:hover": {
                  border: "1px solid #7752fe",
                  backgroundColor: "#7752fe",
                },
              }}
              onClick={async () => {
                await handleClick(status);
                handleClose();
              }}
            >
              {cnfBtnTitle}
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
