import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import { lazy } from "react";
import PathList from "./PathList";
import LoginScreen from "../Pages/Login/Login.jsx";
import Loader from "./Loader.jsx";
import Layout from "../Pages/Layout/MainLayout.jsx";
import PrivateRoute from "../Component/PrivateRoute.jsx";
import UserDetailsScreen from "../Pages/User/UserDetails/UserDetailsScreen.jsx";
import AddNewsScreen from "../Pages/News/AddNewsScreen.jsx";
import UserManagement from "../Pages/User/UserManagementScreen.jsx";

const DashLazyComponent = lazy(() =>
  import("../Pages/Dashboard/DashboardScreen.jsx")
);
const ContentLazyComponent = lazy(() =>
  import("../Pages/Content/ContentManagementScreen.jsx")
);
const NewsLazyComponent = lazy(() => import("../Pages/News/NewsScreen.jsx"));
const PaymentLazyComponent = lazy(() =>
  import("../Pages/Payment/PaymentManagementScreen.jsx")
);
// const UserLazyComponent = lazy(() =>
//   import("../Pages/User/UserManagementScreen.jsx")
// );
const MinerLazyComponent = lazy(() => import("../Pages/Miner/MinerScreen.jsx"));

const ProRouteData = [
  {
    path: PathList._Dashboard,
    element: <DashLazyComponent />,
  },
  {
    path: PathList._Miner,
    element: <MinerLazyComponent />,
  },
  {
    path: PathList._Content,
    element: <ContentLazyComponent />,
  },
  {
    path: PathList._News,
    element: <NewsLazyComponent />,
  },
  {
    path: PathList._Payment,
    element: <PaymentLazyComponent />,
  },
  {
    path: PathList._User,
    element: <UserManagement />,
  },
];

const RouteDate = [
  {
    path: `${PathList._User}/:id`,
    element: <UserDetailsScreen />,
  },
  {
    path: PathList._NEWS_ADD,
    element: <AddNewsScreen />,
  },
];

export default function RouteList() {
  return (
    <div>
      <Routes>
        <Route path={PathList._Login} element={<LoginScreen />} />
        {ProRouteData.map(({ element, path }, i) => (
          <Route
            key={i}
            path={path}
            element={
              <PrivateRoute
                children={
                  <Layout
                    element={
                      <Suspense fallback={() => <Loader />}>{element}</Suspense>
                    }
                  />
                }
              />
            }
          />
        ))}
        {RouteDate.map(({ element, path }, i) => (
          <Route
            key={i}
            path={path}
            element={<Suspense fallback={() => <Loader />}>{element}</Suspense>}
          />
        ))}
      </Routes>
    </div>
  );
}
