import React, { useEffect, useState } from "react";
import { DataListingComponent } from "../../../Component/DataList";
import { Box } from "@mui/material";
import viewIcon from "../../../Assets/image/user/edit.png";
import Loader from "../../../Common/Loader";
import { useParams } from "react-router-dom";
import { POST_API } from "../../../Common/Api/Function";
import apiEndpoints from "../../../Common/Api/apiEndpoints";
import moment from "moment/moment";
const PAGE_SIZE_OPTIONS = [10];

const PurchaseHistoryComponent = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [data, setData] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(PAGE_SIZE_OPTIONS[0]);
  const [totalData, setTotalData] = useState(0);
  const handlePageChange = (e, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (e) => {
    setRowsPerPage(parseInt(e.target.value, 10));
    setPage(0);
  };

  const handleData = async (count = 0) => {
    let data = new FormData();
    data.append("from", "get_miner_purchase");
    data.append("arg", `status = 1 and user_id = ${id}`);
    // data.append("prms", "[1,1]");
    data.append("skip", "0");
    data.append("limit", "30");
    data.append(
      "childEntity",
      '{"user_details":{"select":"id,f_name,l_name,profile_img","from":"user","arg":"id = ?","limit":1,"prms":[":;user_id"]}}'
    );
    data.append("total", "1");
    try
    {
      setLoading(true);
      let res = await POST_API(apiEndpoints.getUserById, data);
      const Rowdata = res?.r?.map((data, i) => ({
        rowid: i + 1,
        ...data,
      }));
      setData(Rowdata);
      // setData(res?.r);
      count === 0 && setTotalData(res.total);
      setIsDataLoading(false);
      setLoading(false);
    } catch (error)
    {
      console.log(error);
      setLoading(false);
      setIsDataLoading(false);
    }
  };

  useEffect(() => {
    handleData();
  }, []);

  const columns = [
    {
      headerClassName: "super-app-theme--header",
      field: "rowid",
      headerName: "Sr No.",
      width: 70,
      headerAlign: "center",
      renderCell: (params) => {
        return params.row.rowid + page * rowsPerPage;
      },
    },
    {
      headerClassName: "super-app-theme--header",
      field: "transaction_id",
      headerName: "Transaction ID",
      flex: 1,
      minWidth: 200,
      headerAlign: "center",
      renderCell: (cell) => `${cell.row.transaction_id ?? "-"}`,
    },
    {
      headerClassName: "super-app-theme--header",
      field: "energy",
      headerName: "W/TH",
      flex: 1,
      minWidth: 160,
      headerAlign: "center",
      renderCell: (e) => e.row.energy + " W/TH",
    },
    {
      headerClassName: "super-app-theme--header",
      field: "level",
      headerName: "Level",
      flex: 1,
      minWidth: 170,
      headerAlign: "center",
      renderCell: (e) => "LEVEL" + e.row.level,
    },
    {
      headerClassName: "super-app-theme--header",
      field: "th",
      headerName: "TH",
      flex: 1,
      minWidth: 160,
      headerAlign: "center",
      renderCell: (e) => e.row.th + " TH",
    },
    {
      headerClassName: "super-app-theme--header",
      field: "date_time",
      headerName: "DATE",
      flex: 1,
      minWidth: 200,
      headerAlign: "center",
      renderCell: (e) => moment(e.row.date_time).format("YYYY-MM-DD"),
    },
    {
      headerClassName: "super-app-theme--header",
      field: "payment_status",
      headerName: "Status",
      flex: 1,
      minWidth: 200,
      headerAlign: "center",
      renderCell: (e) => {
        const { payment_status } = e?.row;
        if (payment_status === 1)
        {
          return <span style={{ color: "green" }}> Paid</span>;
        } else if (payment_status === 0)
        {
          return <span style={{ color: "grey" }}>Pending</span>;
        } else if (payment_status === -1)
        {
          return <span style={{ color: "red" }}>Failed</span>;
        }
      },
    },

    {
      headerClassName: "super-app-theme--header",
      field: "price",
      headerName: "Amount",
      flex: 1,
      minWidth: 200,
      headerAlign: "center",
      renderCell: (cell) => {
        const formatted = cell?.row?.price?.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
        return `$${formatted}`;
      },
    },
  ];
  return isDataLoading ? (
    <Loader />
  ) : (
    <DataListingComponent
      data={data ?? []}
      loading={loading}
      PAGE_SIZE_OPTIONS={PAGE_SIZE_OPTIONS[0]}
      columns={columns}
      totalData={totalData}
      page={page}
      rowsPerPage={rowsPerPage}
      handlePageChange={handlePageChange}
      handleRowsPerPageChange={handleRowsPerPageChange}
    />
  );
};

export default PurchaseHistoryComponent;
