const apiEndpoints = {
  dashboard: {
    getusers: "/api/analytics/total-user",
    getminers: "/api/analytics/total-miner",
    getrevenue: "/api/analytics/total-revenue",
    getBankRequests: "/api/base/get?from=user_bank",
  },
  user: {
    getList: "/user",
    delete: "/user/:id",
  },
  //  Auth
  login: "/api/auth/login",
  forgot: "/api/auth/forgot-password",

  // BANK DETAILS
  handlebankdetails: "/api/base/up/user_bank",

  // NOTIFICATION
  sendnotification: "/api/notification/send",

  // REFRENCE
  refrenceid: "/api/base/up/withdraw",

  // User

  getalluser: (count, prms) => {
    let baseQuery = `api/base/get?select=user.*,countries.country_name&from=user LEFT JOIN countries on user.country_id = countries.id&order=created_at desc &total=1&arg=(CASE WHEN ? = -1 THEN user.status != -1 ELSE user.status = ? END) AND user.role = ?`;
    let filterQuery =
      prms.length > 3 ? ` AND (user.f_name LIKE ? OR user.l_name LIKE ?)` : "";
    return `${baseQuery}${filterQuery}&skip=${count}&limit=15&prms=${JSON.stringify(
      prms
    )}`;
  },
  basePost: "/api/base/get",

  blockuser: "/api/user/account-status",

  // Content Management
  addaboutus: "/api/base/get?from=about_us&limit=1",
  getaboutus: "/api/base/get?from=about_us&limit=1",
  addprivacy: "/api/base/up/privacy_policy/1",
  getprivacy: "/api/base/get?from=privacy_policy&limit=1",
  addterms: "/api/base/up/terms_condition/1",
  getterms: "/api/base/get?from=terms_condition&limit=1",
  addaboutus: "/api/base/up/about_us/1",
  addcontactus: "/api/base/up/contact_us/1",
  getcontactus: "/api/base/get?from=contact_us&limit=1",

  // NEWS
  getnews: (count) =>
    `/api/base/get?from=news&skip=${count}&limit=15&order=created_at desc &total=1&arg=status != ?&prms=[-1]`,
  addnews: "/api/base/in/news",
  updateNews: (id) => `/api/base/up/news/${id}`,
  deletenews: (id) => `/api/base/del/news/${id}`,

  // MINERS
  getallminers: (count, prmsDays, level, effi) => {
    let baseQuery = `/api/base/get?from=get_miner_purchase&arg=status = ? and payment_status = ?`;
    let endQuery = `&prms=[1,1]&childEntity={"user_details":{"select":"id,f_name,l_name,profile_img","from":"user","arg":"id = ?","limit":1,"prms":[":;user_id"]}}&order=date_time desc &total=1`;

    let filterQuery = prmsDays.length
      ? ` and days_active between ${prmsDays[0]} and ${prmsDays[1]}`
      : "";
    let levelQuery = level.length ? ` and th = ${level[0]}` : "";
    let effiQuery = effi.length ? ` and energy = ${effi[0]}` : "";
    return `${baseQuery}${filterQuery}${levelQuery}${effiQuery}${endQuery}&skip=${count}&limit=15`;
  },
  updateminer: (id) => `/api/base/up/miner_purchase/${id}`,
  // PAYMENT
  getallpayments: (count, prms, wd) => {

    let baseQuery = `/api/base/get?select=*&from=withdraw&arg=`;
    let filterQuery = prms ? `status = ${prms}` : "status = ?";
    let withdrawQuery = wd !== null ? ` AND withdraw_status =${wd}` : "";
    let endQuery = `&order=created_at desc &total=1&childEntity={"user_details":{"select":"id,f_name,l_name,profile_img","from":"user","arg":"id = ?","limit":1,"prms":[":;user_id"]}}`;
    return `${baseQuery}${filterQuery}${withdrawQuery}${endQuery}&skip=${count}&limit=15&prms=[1]`;
  },

  getUserById: `/api/base/get`,

  //  getactiveminer:  `api/base/get`
};

export default apiEndpoints;
