import { toast } from "react-toastify";

function ApiError({ error }) {
    if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.message
    )
    {
        if (error?.status === 401)
        {
            localStorage.clear();
            toast.warn("please login to continue.");
            return
        } else
        {
            return console.log(error.response.data.message);
        }
    } else
    {
        return console.log(error && error.message);
    }
}

export default ApiError;
