import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { useEffect, useState } from "react";
import { POST_API } from "../Common/Api/Function";
import apiEndpoints from "../Common/Api/apiEndpoints";
import { toast } from "react-toastify";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 420,
  background: "#EAE8FF",
  borderRadius: "12px",
  boxShadow: 24,
  p: 3,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "1rem",
  flexDirection: "column",
};

export default function RefreceIDModal({ setOpen, open, id, uid, handleUser }) {
  const handleClose = () => setOpen(false);
  const [refID, setRefID] = useState("");
  const [loading, setloading] = useState(false);

  const handleInputChange = (e) => {
    setRefID(e.target.value);
  };

  const handleSubmit = async () => {
    if (refID.trim() !== "") {
      let data = new FormData();

      data.append("reference_no", refID);
      data.append("withdraw_status", 2);
      try {
        setloading(true);
        let res = await POST_API(`${apiEndpoints.refrenceid}/${id}`, data);

        if (res.s) {
          let data = new FormData();
          data.append("uids", uid);
          data.append("title", "MinerX");
          data.append("message", "Your withdraw request is approved.");
          data.append("type", "1");
          data.append("type_id", id);
          data.append("is_store", "1");
          await POST_API(apiEndpoints.sendnotification, data);
          handleUser();
          handleClose();
        } else {
          toast.error(res.m);
        }
        setloading(false);
      } catch (error) {
        console.log(error);
      }
    } else {
      toast.error("please enter Reference ID");
    }
  };
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          ".MuiBackdrop-root": {
            background: "rgba(0, 0, 0, 0.05)",
          },
        }}
      >
        <Box sx={style}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "1rem",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "right",
                width: "100%",
              }}
            >
              <CancelOutlinedIcon
                sx={{ cursor: "pointer" }}
                onClick={handleClose}
              />
            </Box>

            <label htmlFor="roi" style={{ width: "100%" }}>
              <p
                style={{
                  color: "#05001B",
                  fontWeight: "500",
                  fontSize: "16px",
                }}
              >
                Enter Reference Number
              </p>
              <input
                onChange={(e) => handleInputChange(e)}
                onInput={(e) => handleInputChange(e)}
                type="text"
                value={refID}
                name="ref"
                id="ref"
                autoFocus={true}
                placeholder="Enter Refrence ID"
                style={{
                  padding: "14px 18px",
                  border: "1px solid #BBB1FF",
                  background: "#D8D4FF",
                  borderRadius: "11px",
                  outline: "none",
                  color: "#05001B66",
                  width: "100%",
                  marginTop: "10px",
                }}
              />
            </label>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              mt: "0.5rem",
            }}
          >
            <Button
              sx={{
                background: "#7752FE",

                color: "#D8D4FF",
                padding: "8px",
                fontSize: "16px",
                fontWeight: "600",
                textTransform: "none",
                "&:hover": {
                  backgroundColor: "#7775fe",
                },
                width: "150px",
                borderRadius: "8px",
              }}
              onClick={handleSubmit}
            >
              {loading ? "Submitting..." : "Submit"}
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
