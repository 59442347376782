import React, { useEffect, useState } from "react";
import { DataListingComponent } from "../../../Component/DataList";
import { Box } from "@mui/material";
import viewIcon from "../../../Assets/image/user/edit.png";
import { POST_API } from "../../../Common/Api/Function";
import apiEndpoints from "../../../Common/Api/apiEndpoints";
import moment from "moment";
import Loader from "../../../Common/Loader";
import PaymentStatusMenu from "../../Payment/PaymentStatusMenu";
import RefreceIDModal from "../../../Component/RefreceIDModal";
import { useParams } from "react-router-dom";
const PAGE_SIZE_OPTIONS = [10];

const WithdrawComponent = () => {
  const [loading, setLoading] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(PAGE_SIZE_OPTIONS[0]);
  const [totalData, setTotalData] = useState(0);
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [pid, setPid] = useState("");
  const [userid, setUserId] = useState("");
  const { id } = useParams()
  const handlePageChange = (e, newPage) => {
    setPage(newPage);
  };

  const handleData = async (count = 0) => {
    let data = new FormData();
    data.append("select", "*");
    data.append("from", "withdraw");
    data.append("arg", `status = 1 and user_id = ${id}`);
    data.append("skip", count);
    data.append("limit", "15");
    data.append("total", 1);
    data.append("order", "created_at desc");
    data.append(
      "childEntity",
      '{"user_details":{"select":"id,f_name,l_name,profile_img","from":"user","arg":"id = ?","limit":1,"prms":[":;user_id"]}}'
    );

    try
    {
      setLoading(true);
      let res = await POST_API(apiEndpoints.basePost, data);
      const Rowdata = res?.r?.map((data, i) => ({
        rowid: i + 1,
        ...data,
      }));
      setData(Rowdata);
      count === 0 && setTotalData(res.total);
      setIsDataLoading(false);
      setLoading(false);
    } catch (error)
    {
      console.log(error);
      setLoading(false);
      setIsDataLoading(false);
    }
  };

  useEffect(() => {
    handleData();
  }, []);

  const handleRowsPerPageChange = (e) => {
    setRowsPerPage(parseInt(e.target.value, 10));
    setPage(0);
  };

  const columns = [
    {
      headerClassName: "super-app-theme--header",
      field: "rowid",
      headerName: "Sr No.",
      width: 70,
      headerAlign: "center",
      renderCell: (params) => {
        return params.row.rowid + page * rowsPerPage;
      },
    },
    {
      headerClassName: "super-app-theme--header",
      field: "amount",
      headerName: "Amount",
      flex: 1,
      minWidth: 150,
      headerAlign: "center",
      renderCell: (cell) => {
        const formatted = cell?.row?.amount?.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
        return `$${formatted}`;
      },
    },
    {
      headerClassName: "super-app-theme--header",
      field: "date_time",
      headerName: "DATE",
      flex: 1,
      minWidth: 200,
      headerAlign: "center",
      renderCell: (e) => moment(e.row.date_time).format("DD-MM-YYYY"),
    },

    {
      headerClassName: "super-app-theme--header",
      field: "",
      headerName: "TIME",
      flex: 1,
      minWidth: 200,
      headerAlign: "center",
      renderCell: (e) => moment.utc(e.row.date_time).local().format("hh:mm A"),
    },

    {
      headerClassName: "super-app-theme--header",
      field: "pay",
      headerName: "Status",
      width: 150,
      headerAlign: "center",
      renderCell: (e) => (


        <Box
          sx={{
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box sx={{ padding: "1rem" }}>
            <Box sx={{}}>
              <PaymentStatusMenu
                handleUser={handleData}
                setOpen={setOpen}
                id={e.row.id}
                status={e.row?.withdraw_status}
                setUserId={setUserId}
                setPid={setPid}
              />
              <RefreceIDModal
                handleUser={handleData}
                open={open}
                setOpen={setOpen}
                id={pid}
                uid={userid}
              />
            </Box>
          </Box>
        </Box>
      ),
    },
  ];

  return isDataLoading ? (
    <Loader />
  ) : (
    <DataListingComponent
      data={data ?? []}
      loading={loading}
      PAGE_SIZE_OPTIONS={PAGE_SIZE_OPTIONS[0]}
      columns={columns}
      totalData={totalData}
      page={page}
      rowsPerPage={rowsPerPage}
      handlePageChange={handlePageChange}
      handleRowsPerPageChange={handleRowsPerPageChange}
    />
  );
};

export default WithdrawComponent;
