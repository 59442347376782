import React from "react";
import SearchIcon from "@mui/icons-material/Search";
import { Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const CustomSearch = (props) => {
  const { placeholder, onChange, value } = props;
  return (
    <Box
      sx={{
        position: "relative",
      }}
    >
      <input
        type="search"
        name="search"
        autoComplete="off"
        autoFocus="true"
        id="search"
        className="customeSearch"
        style={{
          padding: "14px 18px 14px 35px",
          borderRadius: "6px",
          background: "#BBB1FF80",
          border: "none",
          width: "300px",
          color: "#D8D4FF",
          outline: "none",
          WebkitAppearance: "none",
        }}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
      />
      {/* <style jsx>{`
        input[type="search"]::-webkit-search-cancel-button {
          -webkit-appearance: none;
        }
        input[type="search"]::-ms-clear {
          display: none;
        }
      `}</style> */}
      {/* {value !== "" && (
        <CloseIcon
          sx={{
            right: "10px",
            top: "50%",
            transform: "translateY(-50%)",
            height: "100%",
            fontSize: "22px",
            position: "absolute",
            color: "red",
            cursor: "pointer",
          }}
          onClick={() => {
            // onClear && onClear();
          }}
        />
      )} */}
      <SearchIcon
        sx={{
          left: "10px",
          top: "50%",
          transform: "translateY(-50%)",
          height: "100%",
          fontSize: "20px",
          color: "#D8D4FF",
          position: "absolute",
        }}
      />
      {props.children}
    </Box>
  );
};

export default CustomSearch;
