import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import icon from "../Assets/image/user/filter.png";

export default function FilterComponent({ handlefunction, options }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        sx={{
          fontSize: "16px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "8px 15px",
          background: "#7752FE",
          color: "#D8D4FF",
          textTransform: "none",
          "&:hover": { background: "#7752FE" },
        }}
      >
        <img src={icon} style={{ height: "24px" }} alt="icon" srcSet="" />{" "}
        &nbsp; Filter
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {options &&
          options.map((x) => (
            <MenuItem
              key={x.id}
              onClick={async () => {
                await handlefunction(x.value);
                handleClose();
              }}
            >
              {x.label}
            </MenuItem>
          ))}
      </Menu>
    </div>
  );
}
