import axios from "axios";
import ApiError from "../ApiError";

let token;
let apikey;
const storedData = localStorage.getItem("loginInfo");

if (storedData) {
  const parsedData = JSON.parse(storedData);

  token = parsedData.token;
  apikey = parsedData.apikey;
}

const axiosInstance = axios.create({
  baseURL: "https://api.minerx.app",
  timeout: 3000,
  headers: {
    apikey: apikey ? apikey : "",
    token: token ? token : "",
  },
});

export async function GET_API(url) {
  try {
    const res = await axiosInstance.get(url);
    if (res?.data?.s === 1) {
      return res?.data;
    } else {
    }
  } catch (error) {
    ApiError({ error });
  }
}

export async function POST_API(url, data) {
  try {
    const res = await axiosInstance.post(url, data);
    return res?.data;
  } catch (error) {
    ApiError({ error });
  }
}

export async function GET_BY_ID_API(url, id) {
  try {
    const res = await axiosInstance.get(`${url}/${id}`);
    if (res?.data?.s === 1 && res?.data?.r) {
      return res?.data?.r;
    } else {
      console.log(res.data.m);
    }
  } catch (error) {
    ApiError({ error });
  }
}
