import { BrowserRouter } from 'react-router-dom';
import './App.css';
import RouteList from './Common/RouteList';
import 'react-quill/dist/quill.snow.css';
function App() {
  return (
    <BrowserRouter>
      <RouteList />
    </BrowserRouter>
  );
}

export default App;
