import {
  Box,
  Button,
  TextField,
  IconButton,
  OutlinedInput,
  InputAdornment,
  FormControl,
  FormHelperText,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import React, { useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
// import Cookies from "js-cookie";
import PathList from "../../Common/PathList";
import logo from "../../Assets/image/logo.svg";
import EastRoundedIcon from "@mui/icons-material/EastRounded";
import { POST_API } from "../../Common/Api/Function";
import apiEndpoints from "../../Common/Api/apiEndpoints";

function Login() {
  const navigate = useNavigate();
  const [loginScreen, setLoginScreen] = useState(true);
  const [showPassword, setShowPassword] = useState(false);

  // useEffect(() => {
  //   const savedEmail = Cookies.get("email");
  //   const savedPassword = Cookies.get("password");

  //   if (savedEmail && savedPassword) {
  //     formik.setFieldValue("email", savedEmail);
  //     formik.setFieldValue("password", savedPassword);
  //   }
  // }, []);

  const handleClickShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      remember: false,
    },
    validationSchema: yup.object({
      email: yup
        .string()
        .matches(emailRegex, "Invalid email")
        .required("Required"),
      password: loginScreen
        ? yup
            .string()
            .required("Required")
            .min(6, "Required at least 6 digits.")
            .max(20, "Maximum 20 digits allowed")
        : yup.string().notRequired(),
    }),

    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);

      if (loginScreen) {
        // if (values.remember) {
        //   Cookies.set("email", values.email, { expires: 7 });
        //   Cookies.set("password", values.password, { expires: 7 });
        // } else {
        //   Cookies.remove("email");
        //   Cookies.remove("password");
        // }
        // POST_API
        const res = await POST_API(apiEndpoints.login, values);

        if (res.s) {
          localStorage.setItem("loginInfo", JSON.stringify(res.r));
          toast.success("Login successfully");
          navigate(PathList._Dashboard);
        } else {
          toast.error(res.m);
        }
      } else {
        const res = await POST_API(apiEndpoints.forgot, {
          email: values.email,
        });

        if (res.s) {
          toast.info(res.m);
          setLoginScreen(true);
        } else {
          toast.error(res.m);
        }
      }
      setSubmitting(false);
    },
  });

  return (
    <Box
      sx={{
        background: `linear-gradient(180deg, #05001B 0%, #250C69 100%)`,
        height: "100vh",
        width: "100%",
        padding: "1rem",
      }}
      className="flexBox"
    >
      <Box
        sx={{
          borderRadius: "2rem",
          maxWidth: "500px",
          padding: { xs: "2rem", md: "3rem" },
          // background: `rgba(0,0,0,0.005)`,
          background: `linear-gradient(180deg, rgba(244, 242, 255, 0.064) 0%, rgba(244, 242, 255, 0.224) 120.79%)`,
          borderWidth: "0px 3px 3px 0px",
          borderStyle: "solid",
          borderColor: "#FFFFFF66",
          backdropFilter: "blur(1px)",
          // boxShadow: "1px 1px 3px 0 #FFFFFF66",
          color: "#D8D4FF",
          height: "90vh",
          maxHeight: loginScreen ? "500px" : "410px",
          overflow: "scroll",
        }}
      >
        <Box sx={{ mb: "0.5rem" }}>
          <img src={logo} style={{ width: "200px" }} alt="" />
        </Box>
        <h4 style={{ color: "#9985FF", paddingBottom: "1rem" }}>
          {!loginScreen &&
            "You will receive a link to create a new password via email."}
        </h4>
        <Box>
          <label htmlFor="email">Email Address</label>
          <TextField
            type="email"
            id="email"
            name="email"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
            variant="outlined"
            fullWidth
            focused
            sx={{
              margin: "0.5rem 0 1rem 0",
              ".MuiInputBase-root": {
                borderRadius: "11px",
                background: "#EAE8FF24",
                color: "#D8D4FF",
              },
              ".MuiInputBase-root:hover": {
                background: "#6831F6",
              },
              "& input[type='password']::-ms-reveal": {
                display: "none",
              },
              "& input[type='password']::-ms-clear": {
                display: "none",
              },

              ".MuiOutlinedInput-input:focus": {
                outline: "none",
              },
              fieldset: {
                border: "none",
              },
            }}
            placeholder="Enter your email"
          />
          {loginScreen && (
            <>
              <label htmlFor="password">Password</label>
              <FormControl
                variant="outlined"
                fullWidth
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                sx={{
                  margin: "0.5rem 0 0 0",
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "11px",
                    background: "#EAE8FF24",
                    color: "#D8D4FF",
                  },
                  "& .MuiOutlinedInput-root:hover": {
                    background: "#6831F6",
                  },
                  "& .MuiOutlinedInput-input:focus": {
                    outline: "none",
                  },
                  fieldset: {
                    border: "none",
                  },
                }}
              >
                <OutlinedInput
                  sx={{
                    "& input[type='password']::-ms-reveal": {
                      display: "none",
                    },
                    "& input[type='password']::-ms-clear": {
                      display: "none",
                    },
                  }}
                  type={showPassword ? "text" : "password"}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? (
                          <VisibilityOff style={{ color: "#BBB1FF" }} />
                        ) : (
                          <Visibility style={{ color: "#BBB1FF" }} />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                  id="password"
                  name="password"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.password}
                  onKeyDown={(key) =>
                    key.keyCode === 13 && formik.handleSubmit()
                  }
                  placeholder="******"
                />
                {formik.touched.password && formik.errors.password && (
                  <FormHelperText>{formik.errors.password}</FormHelperText>
                )}
              </FormControl>
            </>
          )}
          {loginScreen ? (
            <Box
              sx={{
                padding: "1rem 0.25rem",
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
              }}
            >
              {/* <FormControlLabel
                control={
                  <Checkbox
                    id="remember"
                    name="remember"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    checked={formik.values.remember}
                    sx={{
                      color: "#9985FF",
                      "&.Mui-checked": {
                        color: "#9985FF",
                      },
                    }}
                  />
                }
                label="Remember me"
                sx={{ color: "#9985FF" }}
              /> */}
              <span
                style={{ cursor: "pointer" }}
                onClick={() => setLoginScreen(false)}
              >
                Forgot password ?
              </span>
            </Box>
          ) : (
            <Button
              variant="text"
              onClick={() => setLoginScreen(true)}
              sx={{
                textTransform: "none",
                paddingBottom: "1rem",
                color: "#9985FF",
              }}
            >
              Back to login
            </Button>
          )}
          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <Button
              variant="contained"
              disabled={formik.isSubmitting}
              onClick={formik.handleSubmit}
              sx={{
                width: "150px",
                textTransform: "none",
                borderRadius: "8px",
                background: "#7752FE",
                "&:hover": {
                  background: "#7752FE75",
                },
              }}
            >
              {formik.isSubmitting
                ? "Submitting..."
                : loginScreen
                ? "Sign In"
                : "Submit"}
              &nbsp;
              {!formik.isSubmitting && <EastRoundedIcon />}
            </Button>
          </Box>
        </Box>
      </Box>{" "}
    </Box>
  );
}

export default Login;
