import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import upload from "../Assets/image/upload.png";
import { toast } from "react-toastify";

export default function SingleImage({
  setImage,
  setPreview,
  error,
  helperText,
  title,
  name,
  apiPreview,
}) {
  const [Preview, setPreviewimg] = useState();

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/*": [],
    },
    onDrop: (acceptedFiles) => {
      const file = acceptedFiles[0];
      if (file) {
        if (file.size <= 5 * 1024 * 1024) {
          const img = new Image();
          img.onload = () => {
            const width = img.width;
            const height = img.height;
            const aspectRatio = width / height;
            if (Math.abs(aspectRatio - 16 / 9) < 0.01) {
              const url = URL.createObjectURL(file);
              setPreviewimg(url);
              setImage(name || "cover", file);
              URL.revokeObjectURL(img.src);
            } else {
              toast.error("Please upload an image with a 16:9 aspect ratio.");
            }
          };
          img.src = URL.createObjectURL(file);
        } else {
          toast.error("Selected file exceeds 5 MB limit.");
        }
      }
    },
  });

  return (
    <div>
      <Box
        sx={{
          background: `linear-gradient(180deg, rgba(216, 212, 255, 0.32) 0%, rgba(153, 133, 255, 0.32) 100%)`,
        }}
        borderRadius="10px"
        border={
          error ? "1px dashed #d32f2f" : "1px dashed rgba(145, 158, 171, 0.32)"
        }
        p={2}
        m={"auto"}
        bgcolor="rgb(244, 246, 248)"
        width={"100%"}
        maxWidth={"540px"}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            justifyContent: "center",
          }}
          {...getRootProps()}
        >
          <input {...getInputProps()} />
          {apiPreview ? (
            <div>
              <figure>
                <img
                  src={apiPreview}
                  alt="preview.."
                  width="100%"
                  height="250px"
                  style={{ objectFit: "contain", borderRadius: "10px" }}
                />
              </figure>
            </div>
          ) : Preview ? (
            <>
              <figure>
                <img
                  src={Preview}
                  alt="preview.."
                  width="100%"
                  height="250px"
                  style={{ objectFit: "contain", borderRadius: "10px" }}
                />
              </figure>
            </>
          ) : (
            <>
              <figure>
                <img
                  src={upload}
                  alt="logo..."
                  width="80px"
                  height="80px"
                  draggable="false"
                  style={{ objectFit: "contain", borderRadius: "10px" }}
                />
              </figure>
              <Box
                sx={{
                  display: { xs: "grid", lg: "flex" },
                  alignItems: "center",
                  justifyContent: "center",
                  placeItems: "center",
                  ":hover": {
                    opacity: "0.72",
                  },
                }}
              >
                <div style={{ textAlign: "center" }}>
                  <Typography
                    fontFamily={"Plus Jakarta Sans"}
                    color={"rgba(255, 255, 255, 0.6)"}
                    variant="h6"
                    fontWeight="700"
                    fontSize={"14px"}
                  >
                    Click here to upload Image <br /> or <br />
                  </Typography>
                  <Typography
                    fontFamily={"Plus Jakarta Sans"}
                    fontWeight="700"
                    color="#fff"
                    fontSize={"14px"}
                  >
                    Drop & Drop
                  </Typography>
                </div>
              </Box>
            </>
          )}
        </div>
      </Box>
      <Typography ml={2} color="#d32f2f" fontSize="0.75rem">
        {helperText}
      </Typography>
    </div>
  );
}
